@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "roboto", Arial, sans-serif;
    scroll-behavior: smooth;
  }
  h1,
  h2,
  h3,
  strong {
    font-family: "jost", Arial, sans-serif;
  }
}

.smooth-transition {
  transition: all 0.2s;
}

#sidebar a.active {
  color: #74a84a;
  font-weight: 500;
}

/* React Mui Slider Custom Style  */
.MuiSlider-root {
  color: #74a84a !important;
}
.MuiSlider-thumb {
  background-color: #74a84a !important;
  width: 20px;
  height: 20px;
}

.MuiSlider-thumb:hover {
  background-color: #4a9130 !important;
}

.MuiSlider-track {
  background-color: #74a84a !important;
  border: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}